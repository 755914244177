import { createApp } from 'vue'
import '@/style.css'
import App from '@/App.vue'
import router from '@/common/router/index'
// import service from '@/common/network/service'

import 'vant/es/toast/style'
import 'vant/es/dialog/style'

const app = createApp(App)
//全局挂载网络请求
// app.config.globalProperties.$request = service
app.use(router)
app.mount('#app')
