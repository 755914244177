
const routes = [
    {
        path: '/',
        component: ()=> import('@/views/home/index_shell/index_shell_page.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        name: 'indexShellPage',
        path: '/indexShellPage',
        component: ()=> import('@/views/home/index_shell/index_shell_page.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        name: 'ipbReportPage',
        path: '/ipbReportPage',
        component: ()=> import('@/views/income_pay_balance_report/ipb_report_page.vue')
    },
    {
        name: 'contractList',
        path: '/contractList',
        component: ()=> import('@/views/contract/contract_list/contract_list.vue')
    },
    //定金列表
    {
        name: 'depositList',
        path: '/depositList',
        component: ()=> import('@/views/deposit/deposit_list/deposit_list_page.vue')
    },
];

export default routes