
<template>
  <!-- <router-view /> -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"  v-if="$route.meta.keepAlive" :key="$route.name"/>
    </keep-alive>
    <component :is="Component"  v-if="!$route.meta.keepAlive" :key="$route.name"/>
  </router-view>
</template>

<script setup>

</script>

<style scoped>

</style>
